import documentSeriesTypeFormStore from '@/modules/document-series-type/store/document-series-type-form-store';
import documentSeriesTypeListStore from '@/modules/document-series-type/store/document-series-type-list-store';

export default {
    namespaced: true,

    modules: {
        form: documentSeriesTypeFormStore,
        list: documentSeriesTypeListStore,
    },
};
